import React, { useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button } from "@mui/material";

const GoogleAnalyticsApp = () => {
	const propertyId = "448839182";
	const startDate = "yyyy-mm-dd";
	const endDate = "yyyy-mm-dd";

	const googleLogin = useGoogleLogin({
		clientId: "954581577567-8mucinvljvod6gtfr7fohpj5n3dn97um.apps.googleusercontent.com",
		responseType: "token",
		onSuccess: async (tokenResponse) => {
			const accessToken = tokenResponse?.access_token;
			if (accessToken) {
				fetchData(accessToken);
			}
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const fetchData = async (accessToken) => {
		try {
			const metrics = [{ name: "activeUsers" }];
			const dimensions = [{ name: "country" }];

			const requestBody = {
				dateRanges: [{ startDate, endDate }],
				metrics,
				dimensions,
			};

			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			};

			const apiResponse = await axios.post(
				`https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runReport`,
				requestBody,
				{ headers }
			);

			const responseData = apiResponse.data;
			console.log("responseData", responseData);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (googleLogin.tokenResponse?.access_token) {
			fetchData(googleLogin.tokenResponse.access_token);
		}
	}, [googleLogin.tokenResponse]);

	return (
		<div className='App'>
			<h1>Google Analytics Report</h1>
			<Button onClick={googleLogin}>Check Analytics</Button>
		</div>
	);
};

export default GoogleAnalyticsApp;

import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../assets/css/detailsmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import SampleImage from "../assets/img/Sample.png";
import Typography from "@mui/material/Typography";
import ImageCarousel from "./ImageCarousel";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import CancelIcon from "@mui/icons-material/Cancel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import { Button, IconButton, Tooltip, ButtonGroup, Avatar } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import GetDistance from "../functions/GetDistance";
// import { Button } from "bootstrap";

const DetailsModal = (props) => {
	const [Merchant, setMerchant] = useState({});
	const [PartyID, setPartyID] = useState();
	const [Amenities, setAmenities] = useState([]);
	const [EVSEs, setEVSEs] = useState([]);
	const [status, setstatus] = useState("");
	const [ImageList, setImageList] = useState([]);
	const [Price, setPrice] = useState(0);
	const [FloorLevel, setFloorLevel] = useState(0);
	const [Coordinates, setCoordinates] = useState({});
	const [Connectors, setConnectors] = useState();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
	// const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

	function isEmptyObject(obj) {
		return (
			obj && // Ensures it's not null or undefined
			typeof obj === "object" && // Ensures it's an object
			obj.constructor === Object && // Ensures it's a plain object
			Object.keys(obj).length === 0 // Ensures it has no own properties
		);
	}

	useEffect(() => {
		console.log(props.onMerchantToShow);
		console.log(props.onNavigationDetails);
		if (!isEmptyObject(props.onMerchantToShow)) {
			setMerchant(props.onMerchantToShow);
			setAmenities(props.onMerchantToShow.facilities);
			console.log("MERCHANT:", props.onMerchantToShow);
			setCoordinates({
				lat: Number(props.onMerchantToShow.coordinates.latitude),
				lng: Number(props.onMerchantToShow.coordinates.longitude),
			});
			setPartyID(props.onMerchantToShow.party_id);
			if (typeof props.onMerchantToShow.facilities == "string") {
				let x = props.onMerchantToShow.facilities;
				let y = JSON.parse(x);
				setAmenities(y);
			}
			if (props.onMerchantToShow.evses.length !== undefined) {
				console.log("DETAILS:", props.onMerchantToShow.evses);
				if (props.onMerchantToShow.evses != undefined) {
					setEVSEs(props.onMerchantToShow.evses);
					props.onMerchantToShow.evses.map((data) => {
						setstatus(data.status);
					});
				}
			}
		}
	}, [props]);

	useEffect(() => {
		console.log("[SELECTED MERCHANT]:", Merchant);
	}, [Merchant]);

	useEffect(() => {
		console.log("EVSEs:", EVSEs);
		if (EVSEs != null) {
			EVSEs?.map((data) => {
				console.log("status:", data);
				setCoordinates({
					lat: Number(data.evse_coodinates_lat),
					lng: Number(data.evse_coodinates_lng),
				});
				setstatus(data.status);
				setImageList(data.evse_images);
				setFloorLevel(data.floor_level);
				console.log(data.connectors);
				setConnectors(data.connectors);
				data.connectors.map((subdata) => {
					console.log(subdata.min_price);
					setPrice(Number(subdata.min_price));
				});
			});
		}
	}, [EVSEs]);

	useEffect(() => {
		let request = GetDistance(props.onUserLocation, Coordinates);
		request.then((res) => {
			console.log(res);
		});
		console.log(Coordinates);
	}, [Coordinates]);

	const toggleNavigate = () => {
		props.onToggleModal(false, undefined);
		props.onSetIsNavigating(true, Coordinates);
		props.onSetNavigationModal(true, Merchant.name);
		props.onSetZoom();
		props.onSetNavModalOpen();
	};

	const toggleOpenExternal = () => {
		let url;
		if (props.onWhatOS === "iOS") {
			url = `http://maps.apple.com/?saddr=${props.onUserLocation.lat},${props.onUserLocation.lng}&daddr=${Coordinates.lat},${Coordinates.lng}`;
		} else {
			url = `https://www.google.com/maps/dir/?api=1&destination=${Coordinates.lat},${Coordinates.lng}`;
		}
		window.open(url, "_blank");
	};

	return (
		<div className='details-modal'>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					// marginBottom: 4,
					alignItems: "center",
					backgroundColor: "#2829a6",
					padding: "8px",
					// color: "white",
					// backgroundColor:
					// 	status === "AVAILABLE"
					// 		? "green"
					// 		: status === "CHARGING"
					// 		? "blue"
					// 		: status === "RESERVED"
					// 		? "orange"
					// 		: "red",
				}}>
				{/* <Box>
					<CloseIcon
						onClick={() => props.onToggleModal(false, undefined)}
						sx={{ cursor: "pointer" }}
					/>
					<IconButton></IconButton>
				</Box> */}
				<CloseIcon
					onClick={() => props.onToggleModal(false, undefined)}
					sx={{ cursor: "pointer", color: "white" }}
				/>
				{/* 
				<Chip
					label={status ? status : "STATUS NOT AVAILABLE"}
					color={
						status === "AVAILABLE"
							? "success"
							: status === "CHARGING"
							? "primary"
							: status === "RESERVED"
							? "warning"
							: "error"
					}
					icon={
						status === "AVAILABLE" ? (
							<CheckCircleIcon />
						) : status === "CHARGING" ? (
							<OfflineBoltIcon />
						) : status === "RESERVED" ? (
							<BookmarkAddedIcon />
						) : status === "OFFLINE" ? (
							<DoNotDisturbOnIcon />
						) : (
							<CancelIcon />
						)
					}
				/> */}
			</div>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: { lg: "row", xs: "column" },
					height: "100%",
					overflow: "auto",
				}}>
				<div className='merchant-details'>
					<div className='details-left'>
						<ImageCarousel
							onImageList={ImageList}
							sx={{ height: "100%", boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)" }}
						/>
						{/* <img src={SampleImage} style={{ height: '100%' }} /> */}
					</div>
					<div className='details-right'>
						<div
							style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "unset" }}
							gutterBottom>
							<Typography variant='h5'>{Merchant.name}</Typography>
							{/* <Typography
								variant='subtitle2'
								sx={{ fontSize: "16px", fontStyle: "italic", whiteSpace: "nowrap", alignSelf: "flex-end" }}>
								&#8369; {Price === undefined || Price === 0 ? "-" : <>{Price.toFixed(2)}/KW-H</>}
							</Typography> */}
						</div>
						<Typography
							sx={{ width: "90%" }}
							variant='subtitle2'
							color='text.secondary'>
							{/* <span>
								Floor Level:{" "}
								{FloorLevel !== undefined && FloorLevel !== null
									? Number(FloorLevel) < 0
										? "LG"
										: Number(FloorLevel) === 1
										? "UG"
										: FloorLevel + "F"
									: "N/A"}
							</span> */}
							<br />
							<a
								href={`http://maps.apple.com/?q=${Merchant.address}`}
								target='_blank'>
								{Merchant.address}, {Merchant.postal_code}
							</a>
						</Typography>

						{PartyID === "PAR" && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<div className='charger-settings-list'>
									{/* <Chip label='Current type: AC' color="primary" /> */}
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Current type: AC
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Power: 7KW
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Plug type: Type 2 x 1 unit
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;With App activation via QR
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Payment Types available: Maya, Gcash & Credit card
										</Typography>
									</span>
								</div>
							</>
						)}
						{Amenities && Amenities !== undefined && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Typography
										sx={{ fontSize: "16px" }}
										variant='subtitle2'>
										Amenities available:
									</Typography>
									<div
										style={{
											padding: "8px",
											display: "flex",
											flexDirection: "row",
											lineHeight: "16px",
											flexWrap: "wrap",
											gap: 5,
										}}>
										{Amenities &&
											Amenities !== undefined &&
											Amenities.map((item) => {
												const word = item.replaceAll("_", " ").toLowerCase();
												const firstLetter = word.charAt(0);
												const firstLetterCap = firstLetter.toUpperCase();
												const remainingLetters = word.slice(1);
												const capitalizedWord = firstLetterCap + remainingLetters;
												return (
													<Chip
														label={capitalizedWord}
														color='primary'
													/>
												);
											})}
									</div>
								</div>
							</>
						)}

						{Merchant.website !== undefined && Merchant.website !== null && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<Typography variant='subtitle2'>
									<span style={{ display: "flex", justifyContent: "space-between" }}>
										Visit our page:&nbsp;{" "}
										<a
											href={Merchant.website}
											target='_blank'>
											{Merchant.website}
										</a>
									</span>
								</Typography>
							</>
						)}
					</div>
				</div>
				<Box sx={{ height: "100%", width: "100%", padding: "16px" }}>
					<Typography
						variant='h5'
						sx={{ fontWeight: "bold" }}>
						Charger List
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center", marginTop: "8px" }}>
						{EVSEs != null &&
							EVSEs?.map((evse_data) => {
								console.log("[EVSE]", evse_data);
								return (
									<Accordion
										sx={{
											width: "100%",
											borderRadius: "16px",
											boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
											marginTop: "8px",
											paddingTop: { xs: "32px", lg: "unset" },
										}}>
										{/* <Box sx={{ width: "20%", height: "110px", backgroundColor: "black" }}></Box> */}
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1-content'
											id='panel1-header'>
											<Box
												sx={{
													width: "100%",
													padding: "8px",
													display: "flex",
													flexDirection: "column",
													// alignItems: "flex-end",
												}}>
												<Box
													sx={{
														width: "100%",
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}>
													<Typography
														variant='h6'
														sx={{ fontWeight: "bold" }}>
														{evse_data.evse_id}
													</Typography>
													<Chip
														sx={{ display: { xs: "none", lg: "flex" } }}
														label={evse_data.status ? evse_data.status : "STATUS NOT AVAILABLE"}
														color={
															evse_data.status === "AVAILABLE"
																? "success"
																: evse_data.status === "CHARGING"
																? "primary"
																: evse_data.status === "RESERVED"
																? "warning"
																: "error"
														}
														icon={
															evse_data.status === "AVAILABLE" ? (
																<CheckCircleIcon />
															) : evse_data.status === "CHARGING" ? (
																<OfflineBoltIcon />
															) : evse_data.status === "RESERVED" ? (
																<BookmarkAddedIcon />
															) : evse_data.status === "OFFLINE" ? (
																<DoNotDisturbOnIcon />
															) : (
																<CancelIcon />
															)
														}
													/>
												</Box>
												<Box>
													{/* <Typography variant='subtitle2'>Charger Details</Typography> */}

													<Typography variant='subtitle2'>
														Location:{" "}
														{evse_data.floor_level !== undefined && evse_data.floor_level !== null
															? Number(evse_data.floor_level) < 0
																? "LG"
																: Number(evse_data.floor_level) === 1
																? "UG"
																: evse_data.floor_level + "F"
															: "N/A"}
													</Typography>
												</Box>
												<Chip
													sx={{
														alignSelf: "flex-end",
														position: "absolute",
														transform: "translate(35%, -140%)",
														display: { lg: "none" },
													}}
													label={evse_data.status ? evse_data.status : "STATUS NOT AVAILABLE"}
													color={
														evse_data.status === "AVAILABLE"
															? "success"
															: evse_data.status === "CHARGING"
															? "primary"
															: evse_data.status === "RESERVED"
															? "warning"
															: "error"
													}
													icon={
														evse_data.status === "AVAILABLE" ? (
															<CheckCircleIcon />
														) : evse_data.status === "CHARGING" ? (
															<OfflineBoltIcon />
														) : evse_data.status === "RESERVED" ? (
															<BookmarkAddedIcon />
														) : evse_data.status === "OFFLINE" ? (
															<DoNotDisturbOnIcon />
														) : (
															<CancelIcon />
														)
													}
												/>
											</Box>
										</AccordionSummary>
										<AccordionDetails sx={{ paddingTop: "8px" }}>
											<Box sx={{ borderTop: "dotted .05px gray", paddingTop: "8px" }}>
												<Typography variant='subtitle2'>Connector/Plug List</Typography>
												{Connectors !== undefined && Connectors.length > 0 && (
													<Box>
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																gap: 1,
																padding: "8px",
																alignItems: "center",
															}}>
															{Connectors.map((data) => {
																console.log(data);
																return (
																	<Chip
																		className='connector-chip'
																		sx={{
																			display: "flex",
																			justifyContent: "unset",
																			width: "100%",
																			height: "auto",
																			borderRadius: "0 8px 8px 0",
																			"& .MuiChip-label": {
																				padding: "unset",
																			},
																		}}
																		label={
																			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
																				<Box sx={{ display: "flex", alignItems: "center" }}>
																					<Box
																						sx={{
																							backgroundColor: "white",
																							padding: "8px",
																							backgroundColor: "#2829a6",
																							color: "white",
																						}}>
																						<Typography
																							variant='h6'
																							sx={{ fontWeight: "bold" }}>
																							{data.connector_id === 1 ? "A" : "B"}
																						</Typography>
																					</Box>

																					<Box sx={{ padding: "8px", display: "flex", gap: 1 }}>
																						<Chip
																							sx={{
																								"& .MuiChip-label": {
																									padding: "12px",
																								},
																							}}
																							size='small'
																							label={
																								<Typography
																									variant='subtitle2'
																									fontSize={"12px"}>
																									{data.power_type}
																								</Typography>
																							}
																						/>
																						<Chip
																							sx={{
																								"& .MuiChip-label": {
																									padding: "12px",
																								},
																							}}
																							size='small'
																							label={
																								<Typography
																									variant='subtitle2'
																									fontSize={"12px"}>
																									{data.standard}
																								</Typography>
																							}
																						/>
																						<Chip
																							sx={{
																								"& .MuiChip-label": {
																									padding: "12px",
																								},
																							}}
																							size='small'
																							label={
																								<Typography
																									variant='subtitle2'
																									fontSize={"12px"}>
																									{data.kwh} KW-H
																								</Typography>
																							}
																						/>
																					</Box>
																				</Box>
																				<Box></Box>
																			</Box>
																		}
																	/>
																);
															})}
														</Box>
													</Box>
												)}
												<Box
													sx={{
														display: "flex",
														width: "100%",
														justifyContent: "flex-end",
														gap: 1,
														marginTop: "16px",
													}}>
													<Tooltip title='Coming Soon...'>
														<Box>
															<Button
																size='small'
																variant='contained'
																disabled>
																Reserve Charger
															</Button>
														</Box>
													</Tooltip>
													<Tooltip title='Coming Soon...'>
														<Box>
															<Button
																size='small'
																variant='contained'
																disabled>
																Start Charger
															</Button>
														</Box>
													</Tooltip>
												</Box>
											</Box>
										</AccordionDetails>
									</Accordion>
								);
							})}
					</Box>
				</Box>
			</Box>

			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",

					// marginBottom: 4,
					alignItems: "center",
					boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
					padding: "16px",
				}}>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column", // maxWidth 800px on medium screens
							lg: "row",
						},
						alignItems: "flex-end",
						width: "100%",
						gap: 1,
					}}>
					<ButtonGroup
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column-reverse", // maxWidth 800px on medium screens
								lg: "row",
							},
							gap: 1,
						}}
						fullWidth>
						<Button
							variant={isLargeScreen ? "text" : "contained"}
							color={!isLargeScreen ? "success" : "primary"}
							onClick={() => toggleOpenExternal()}>
							Locate/Navigate in External App
						</Button>
						<Button
							variant={isLargeScreen ? "text" : "contained"}
							onClick={() => toggleNavigate()}>
							Navigate ({!isEmptyObject(props.onNavigationDetails) && props.onNavigationDetails?.distance.text} away)
						</Button>
					</ButtonGroup>
				</Box>
			</div>
		</div>
	);
};

export default DetailsModal;

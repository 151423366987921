import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DetailsModal from "./components/DetailsModal";
import MapComponent from "./components/MapComponent";
import BottomModal from "./components/BottomModal";
import SystemLogo from "./assets/img/system-logo.png";
import ParkNchargeLogo from "./assets/img/parkncharge-logo.svg";
import BlackBackground from "./components/BlackBackground";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { io } from "socket.io-client";
import React, { useEffect, useState, useRef } from "react";
import { Typography, Box, Button, Modal, Grid, ClickAwayListener, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Nav } from "react-bootstrap";
import ReactGA from "react-ga4";
import "./assets/css/index.css";

import InfoIcon from '@mui/icons-material/Info';

import GetFilterDefaultData from "./functions/GetFilterDefaultData";

import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleAnalyticsApp from './components/GoogleAnalyticsApp';

ReactGA.initialize('G-H5DGBQ1PWN');

const token = process.env.REACT_APP_TOKEN;
// https://stg-ocpi.sysnetph.com/ocpi/ws/authenticate
const socket = io(process.env.REACT_APP_MODE === "PROD" ? "https://emsp-socketserver.parkncharge.com.ph/authenticate" : "https://stg-ocpiwss.sysnetph.com/authenticate", {
  transports: ['websocket'],
  withCredentials: true,
  rejectUnauthorized: false,
  auth: {
    token: token
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 800,
    marginTop: "unset!important"
  },
});


const App = () => {
  const [cookies, setCookie] = useCookies(['visited']);
  const [IsAckToolTipVisible, setIsAckToolTipVisible] = useState(false);
  const [pageviews, setPageviews] = useState("-");
  const [deviceType, setDeviceType] = useState('');
  const [EVSEsDetails, setEVSEsDetails] = useState([]);
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [IsNavigationModalVisible, setIsNavigationModalVisible] = useState(false);
  const [CurrentMerchant, setCurrentMerchant] = useState('');
  const [MerchantDetails, setMerchantDetails] = useState({})
  const [UserLocation, setUserLocation] = useState({});
  const [IsAccurateLocation, setIsAccurateLocation] = useState(false);
  const [UpdateEVSE, setUpdateEVSE] = useState({});
  const [open, setOpen] = useState(false);
  const [NavigationDetails, setNavigationDetails] = useState({});
  const [FilterDefaultData, setFilterDefaultData] = useState();
  const [DirectionRespose, setDirectionResponse] = useState("");
  const [NavDestination, setNavDestination] = useState("");

  const [Navigate, setNavigate] = useState(() => { });
  const [ZoomFunc, setZoomFunc] = useState(() => { });
  const [CancelNavigation, setCancelNavigation] = useState(() => { });
  const directionsServiceRef = useRef(null);

  const [IsNavigating, setIsNavigating] = useState(false);
  const [IsNavigationModalOpen, setIsNavigationModalOpen] = useState(false);
  const [DestinationCoordinates, setDestinationCoordinates] = useState({});
  const intervalRef = useRef(null);
  const location = useLocation();


  const handleNavOpen = () => setIsNavigationModalOpen(true);
  const handleNavClose = () => setIsNavigationModalOpen(false);


  const handleTooltipClose = () => {
    setIsAckToolTipVisible(false);
  };

  const handleTooltipOpen = () => {
    setIsAckToolTipVisible(true);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };


  const successCallback = (position) => {
    console.log("[GetUserLocation]: SUCCESS!")
    // setUserLocation({ lat: 0, lng: 0 })
    setTimeout(() => {
      setUserLocation({
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude)
      });
      localStorage.setItem("user_last_location", JSON.stringify({
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude)
      }))
      if (position.coords.accuracy <= 100) {
        setIsAccurateLocation(true)
      } else {
        handleClick();
        setIsAccurateLocation(false);
      }
    }, 500)

  };

  const errorCallback = (error) => {
    setUserLocation({ lat: 0, lng: 0 })
    // alert(JSON.stringify(error))
    console.log(error);
  };

  const toggleDistance = (navDetails, func = undefined) => {
    setNavigationDetails(navDetails)
    if (func !== undefined) {
      setCancelNavigation(() => func);
    }
  }

  const toggleDirectionResponse = (directionRes) => {
    setDirectionResponse(directionRes);
  }

  const handleNavigationRef = (navRef) => {
    directionsServiceRef.current = navRef;
  }

  // const FilterInit = () => {
  //   let request = GetFilterDefaultData();
  //   request.then((res) => {
  //     if (res.status === 200) {
  //       setFilterDefaultData(res.data);
  //     }
  //     console.log(res);
  //   })
  // }

  const toggleFilter = (power_types = null, connector_types = null, facilities = null, capabilities = null, payment_types = null, parking_types = null, location = null) => {
    socket.emit(
      "emsp.location-filter-req",
      {
        data: {
          power_types: (power_types !== null && power_types.length > 0) ? power_types : null,
          facilities: (facilities !== null && facilities.length > 0) ? facilities : null,
          connector_type: (connector_types !== null && connector_types.length > 0) ? connector_types : null,
          capabilities: (capabilities !== null && capabilities.length > 0) ? capabilities : null,
          payment_types: (payment_types !== null && payment_types.length > 0) ? payment_types : null,
          parking_types: (parking_types !== null && parking_types.length > 0) ? parking_types : null,
          nearby:
            location === "1" || location === "5" || location === "10"
              ? { center: UserLocation, radius: location }
              : null,
          city: null,
        },
      },
      (res) => {
        console.log("ack:", res); //server acknowledgement response
      }
    );
  };

  const hasLocalStorageValue = (key) => {
    const value = localStorage.getItem(key);
    return value !== null && value !== '';
  }

  const setEndOfDayCookie = (name, value) => {
    // Get the current date
    const now = new Date();

    // Set the expiration time to midnight
    const expire = new Date();
    expire.setHours(23, 59, 59, 999);

    // Calculate the remaining time in milliseconds
    const remainingTime = expire - now;

    // Set the cookie with the expiration time
    setCookie(name, value, { path: '/', expires: new Date(now.getTime() + remainingTime) });
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Android detection
    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    }

    // iOS detection
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    }
    else {
      setDeviceType('other');
    }

    localStorage.removeItem("stored_filter")
    // FilterInit();
    if (cookies["visited"] === undefined) {
      socket.emit("emsp.site-counter-req",
        (res) => {
          setEndOfDayCookie("visited", "true")
          console.log("ack:", res); //server acknowledgement response
        })
    }

    let tmp_List;

    socket.on('connection', function (socket) {
      socket.on('disconnect', function () {
        console.log("disconnect: ", socket.id);
      });
    });
    // client-side
    socket.on("connect", () => {
      console.log("SOCKET_ID:", socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.on("emsp.location-boot", (data) => {
      console.log("BOOT", data);
      tmp_List = data;
      setEVSEsDetails(data);
    });

    socket.on("emsp.filter-boot", (data) => {
      console.log(data)
      setFilterDefaultData(data);
    })

    socket.on("emsp.site-counter-res", (data) => {
      console.log(data)
      setPageviews(data);
    })


    socket.on("emsp.location-filter-res", (data) => {
      console.log("[Filter]:", data);
      tmp_List = data;
      setEVSEsDetails(data);
    });

    socket.on("emsp.evse-status-update", (arg, ack) => {
      console.log(arg)
      setUpdateEVSE(arg);
      if (ack) {
        ack({ msg: 'location-update received' });
      }
    });


    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      console.log(err instanceof Error); // true
      console.log(err.message); // not authorized 
    });

    if (hasLocalStorageValue("user_last_location")) {
      let lastLocation = JSON.parse(localStorage.getItem("user_last_location"));
      setUserLocation(lastLocation)
    }

    if ('geolocation' in navigator) {
      const watcher = navigator.geolocation.watchPosition(successCallback, errorCallback, options);
      return () => navigator.geolocation.clearWatch(watcher);
    }


  }, [])

  useEffect(() => {
    console.log(deviceType)
  }, [deviceType])



  const toggleIsModalVisible = (bool, evseID) => {
    setIsModalVisible(bool);
    setCurrentMerchant(evseID);
    if (!bool) {
      setCurrentMerchant('');
      setMerchantDetails({});
    }
    console.log(evseID)
  }

  useEffect(() => {
    if (CurrentMerchant !== '') {
      EVSEsDetails.map((data) => {
        if (data.id === CurrentMerchant) {
          console.log("CURRENT MERCHANT DATA:", data);
          setMerchantDetails(data)
        }
      })
    }
  }, [CurrentMerchant])

  useEffect(() => {
    if (UpdateEVSE != null) {
      console.log(EVSEsDetails)
      let tmp_EVSEsDetails = [...EVSEsDetails];
      tmp_EVSEsDetails.map((tmp_data) => {
        if (tmp_data?.id === Number(UpdateEVSE.data.id)) {
          tmp_data.evses.map((tmp_subdata) => {
            console.log(tmp_subdata?.evse_id, UpdateEVSE.data.evse_id)
            if (tmp_subdata?.evse_id === UpdateEVSE.data.evse_id) {
              console.log(tmp_subdata)
              tmp_subdata.status = UpdateEVSE.data.status;
              // tmp_subdata.connectors?.map((tmp_connector_data) => {
              //   UpdateEVSE.data.connector_details?.map((conn) => {
              //     if (tmp_connector_data?.connector_id === Number(conn.connector_id)) {
              //       tmp_connector_data.status = conn.connector_status;
              //     }
              //   })

              // })
            }

          })
        }
      })

      setEVSEsDetails(tmp_EVSEsDetails)
      tmp_EVSEsDetails.map((evse) => {
        console.log(evse, CurrentMerchant)
        if (evse.id === CurrentMerchant) {
          setMerchantDetails(evse)
        }
      })
    }
  }, [UpdateEVSE]);


  const handleNavigation = (nav) => {
    setNavigate(() => nav);
  }

  const handleZoom = (func) => {
    setZoomFunc(() => func);
  }

  const toggleNavigationModal = (bool, destination = undefined) => {
    setIsNavigationModalVisible(bool);
    if (destination !== undefined) {
      setNavDestination(destination)
    }
  }

  useEffect(() => {
    if (IsNavigating && DestinationCoordinates && UserLocation) {
      Navigate(UserLocation, DestinationCoordinates)
      // intervalRef.current = setInterval(() => {
      //   Navigate(UserLocation, DestinationCoordinates)
      // }, 5000);
    } else {
      clearInterval(intervalRef.current);
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [IsNavigating, DestinationCoordinates, UserLocation]);

  const toggleIsNavigating = (bool, coordinates) => {
    setIsNavigating(bool);
    setDestinationCoordinates(coordinates)
  }

  return (
    <div className="App">
      {
        IsModalVisible && <BlackBackground onToggleModal={toggleIsModalVisible} />
      }
      {
        IsModalVisible &&
        <DetailsModal onToggleModal={toggleIsModalVisible} onMerchantList={EVSEsDetails} onMerchantToShow={MerchantDetails} onUserLocation={UserLocation} onNavigationDetails={NavigationDetails} onNavigate={Navigate} onSetNavigationModal={toggleNavigationModal} onSetZoom={ZoomFunc} onSetIsNavigating={toggleIsNavigating} onSetNavModalOpen={handleNavOpen} onWhatOS={deviceType} />
      }

      <Modal
        open={IsNavigationModalOpen}
        onClose={handleNavClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* const style = {
          position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
}; */}
        <Box sx={{ borderRadius: "16px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, backgroundColor: "background.paper", boxShadow: 24, padding: 4 }}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Typography id="modal-modal-description" sx={{ textAlign: "center" }}>
            Map interaction is disabled in navigation mode, stop navigation to enable it again.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleNavClose} sx={{ mt: 2 }}>OK</Button>
          </Box>

        </Box>
      </Modal>
      <Grid item className='site-ack'>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <CustomWidthTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={IsAckToolTipVisible}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement='bottom'
              arrow
              title={<p>Acknowledgment:<br /><br />The following EVCS sites were taken from internet, DoE’s list and other partner’s site.  We will continuously update the station site when they are available.</p>}>
              <IconButton
                onClick={() => handleTooltipOpen()}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <InfoIcon fontSize='small' />
              </IconButton>
              {/* <Button>Click</Button> */}
            </CustomWidthTooltip>
          </div>
        </ClickAwayListener>
      </Grid>

      {/* MAIN BODY---------------------------------------------------------------------------------------------------------------------------> */}
      <div className="app-header">
        <img className="app-system-logo" src={SystemLogo} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: { lg: "16px" } }}>
          <Typography className="banner-message" variant="h5" component="div" sx={{ fontStyle: 'italic', color: "#125EDE", fontWeight: "bold" }}>Dedicated in helping EVs to travel farther.</Typography>
          <Typography className="banner-message" variant="h5" component="div" sx={{ fontStyle: 'italic', color: "#125EDE", fontWeight: "bold" }}>An OCPI Hub initiative.</Typography>
        </Box>

      </div>
      <MapComponent mapDetails={EVSEsDetails} onToggleModal={toggleIsModalVisible} onUserLocation={UserLocation} onIsAccurateLocation={IsAccurateLocation} onToggleDistance={toggleDistance} navigationRef={directionsServiceRef} onSetNavigationFunc={handleNavigation} onSetZoomFunc={handleZoom} onNavigationDetails={NavigationDetails} onFilterDefaultData={FilterDefaultData} onSocket={toggleFilter} onIfNavigating={IsNavigating} onSiteVisitCount={pageviews} />
      {IsNavigationModalVisible && <BottomModal onNavigationDetails={NavigationDetails} onSetNavigationModal={toggleNavigationModal} onCancelNavigation={CancelNavigation} onNavDestination={NavDestination} onSetIsNavigating={toggleIsNavigating} />}

      <div className="app-footer">
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>&copy; 2024 FindEVPlugPH. All rights reserved.</Typography>
      </div>
      {/* <GoogleOAuthProvider clientId={"954581577567-8mucinvljvod6gtfr7fohpj5n3dn97um.apps.googleusercontent.com"}>
        <GoogleAnalyticsApp />
      </GoogleOAuthProvider> */}
      {/* <----------------------------------------------------------------------------------------------------------------------MAIN BODY */}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Location not found/not accurate. Try restarting your device location provider (eg. GPS).
        </Alert>
      </Snackbar>
    </div>
  );
};

export default App;
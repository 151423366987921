import {
	Box,
	IconButton,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormLabel,
	Button,
	Typography,
	ButtonGroup,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import "../assets/css/filtermodal.css";

const FilterModal = (props) => {
	const [IsAdvanceVisible, setIsAdvanceVisible] = useState(false);
	const [PowerTypes, setPowerTypes] = useState([]);
	const [Capabilities, setCapabilities] = useState([]);
	const [ConnectorTypes, setConnectorTypes] = useState([]);
	const [Facilities, setFacilities] = useState([]);
	const [ParkingTypes, setParkingTypes] = useState([]);
	const [PaymentTypes, setPaymentTypes] = useState([]);

	const [SelectedLocation, setSelectedLocation] = useState("");
	const [SelectedPowerTypes, setSelectedPowerTypes] = useState([]);
	const [SelectedConnectorTypes, setSelectedConnectorTypes] = useState([]);
	const [SelectedFacilities, setSelectedFacilities] = useState([]);
	const [SelectedCapabilities, setSelectedCapabilities] = useState([]);
	const [SelectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
	const [SelectedParkingTypes, setSelectedParkingTypes] = useState([]);
	const [IsSelectionEmpty, setIsSelectionEmpty] = useState(true);

	const [IsLocationExpanded, setIsLocationExpanded] = useState(false);
	const [IsPowerTypesExpanded, setIsPowerTypesExpanded] = useState(false);
	const [IsConnectorTypesExpanded, setIsConnectorTypesExpanded] = useState(false);
	const [IsFacilitiesExpanded, setIsFacilitiesExpanded] = useState(false);
	const [IsCapabilitiesExpanded, setIsCapabilitiesExpanded] = useState(false);
	const [IsPaymentTypesExpanded, setIsPaymentTypesExpanded] = useState(false);
	const [IsParkingTypesExpanded, setIsParkingTypesExpanded] = useState(false);

	const toggleAdvance = () => {
		setIsAdvanceVisible(!IsAdvanceVisible);
	};

	const toggleFilter = () => {
		props.onSetModalVisibility(false);
		localStorage.setItem(
			"stored_filter",
			JSON.stringify({
				power_types: SelectedPowerTypes,
				connector_types: SelectedConnectorTypes,
				facilities: SelectedFacilities,
				capabilities: SelectedCapabilities,
				payment_types: SelectedPaymentTypes,
				parking_types: SelectedParkingTypes,
				location: SelectedLocation,
			})
		);
		props.onSocket(
			SelectedPowerTypes,
			SelectedConnectorTypes,
			SelectedFacilities,
			SelectedCapabilities,
			SelectedPaymentTypes,
			SelectedParkingTypes,
			SelectedLocation
		);
		props.onSetFilterAlertVisible();
	};

	const toggleClearFilter = () => {
		localStorage.removeItem("stored_filter");
		setSelectedPowerTypes([]);
		setSelectedConnectorTypes([]);
		setSelectedFacilities([]);
		setSelectedCapabilities([]);
		setSelectedPaymentTypes([]);
		setSelectedParkingTypes([]);
		setSelectedLocation("");
		props.onSocket();
		props.onSetModalVisibility(false);
	};

	useEffect(() => {
		const storedFilter = JSON.parse(localStorage.getItem("stored_filter"));
		if (storedFilter !== null) {
			setSelectedPowerTypes(storedFilter.power_types);
			setSelectedConnectorTypes(storedFilter.connector_types);
			setSelectedFacilities(storedFilter.facilities);
			setSelectedCapabilities(storedFilter.capabilities);
			setSelectedPaymentTypes(storedFilter.payment_types);
			setSelectedParkingTypes(storedFilter.parking_types);
			setSelectedLocation(storedFilter.location);
			setIsSelectionEmpty(false);
		}
		console.log(storedFilter);
	}, []);

	useEffect(() => {
		if (props.onFilterDefaultData !== undefined) {
			console.log(props.onFilterDefaultData);
			setPowerTypes([
				{
					code: "AC",
					description: "AC",
				},
				{
					code: "DC",
					description: "DC",
				},
			]);
			setCapabilities(props.onFilterDefaultData.capabilities);
			setConnectorTypes(props.onFilterDefaultData.connectorTypes);
			setFacilities(props.onFilterDefaultData.facilities);
			setParkingTypes(props.onFilterDefaultData.parkingTypes);
			setPaymentTypes(props.onFilterDefaultData.paymentTypes);
		}
		console.log(props.onFilterDefaultData);
	}, [props]);

	useEffect(() => {
		console.log(PowerTypes);
	}, [PowerTypes]);

	const toggleItemInArray = (array, item) => {
		if (array.includes(item)) {
			return array.filter((i) => i !== item);
		} else {
			return [...array, item];
		}
	};

	const handleFilterChange = (event, key, value) => {
		if (key === "power_types") {
			if (value === "ALL") {
				if (SelectedPowerTypes === "ALL") {
					setSelectedPowerTypes([]);
				} else {
					setSelectedPowerTypes("ALL");
				}
			} else {
				if (SelectedPowerTypes === "ALL") {
					setSelectedPowerTypes([]);
					PowerTypes.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedPowerTypes((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedPowerTypes((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		} else if (key === "connector_types") {
			if (value === "ALL") {
				if (SelectedConnectorTypes === "ALL") {
					setSelectedConnectorTypes([]);
				} else {
					setSelectedConnectorTypes("ALL");
				}
			} else {
				if (SelectedConnectorTypes === "ALL") {
					setSelectedConnectorTypes([]);
					ConnectorTypes.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedConnectorTypes((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedConnectorTypes((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		} else if (key === "facilities") {
			if (value === "ALL") {
				if (SelectedFacilities === "ALL") {
					setSelectedFacilities([]);
				} else {
					setSelectedFacilities("ALL");
				}
			} else {
				if (SelectedFacilities === "ALL") {
					setSelectedFacilities([]);
					Facilities.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedFacilities((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedFacilities((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		} else if (key === "capabilities") {
			if (value === "ALL") {
				if (SelectedCapabilities === "ALL") {
					setSelectedCapabilities([]);
				} else {
					setSelectedCapabilities("ALL");
				}
			} else {
				if (SelectedCapabilities === "ALL") {
					setSelectedCapabilities([]);
					Capabilities.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedCapabilities((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedCapabilities((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		} else if (key === "payment_types") {
			if (value === "ALL") {
				if (SelectedPaymentTypes === "ALL") {
					setSelectedPaymentTypes([]);
				} else {
					setSelectedPaymentTypes("ALL");
				}
			} else {
				if (SelectedPaymentTypes === "ALL") {
					setSelectedPaymentTypes([]);
					PaymentTypes.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedPaymentTypes((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedPaymentTypes((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		} else {
			if (value === "ALL") {
				if (SelectedParkingTypes === "ALL") {
					setSelectedParkingTypes([]);
				} else {
					setSelectedParkingTypes("ALL");
				}
			} else {
				if (SelectedParkingTypes === "ALL") {
					setSelectedParkingTypes([]);
					ParkingTypes.map((item) => {
						console.log(item.code);
						if (item.code !== value) {
							setSelectedParkingTypes((prevItems) => [...prevItems, item.code]);
						}
					});
				} else {
					setSelectedParkingTypes((prevItems) => toggleItemInArray(prevItems, value));
				}
			}
		}
	};

	const checkIfAllIncluded = (List, selectedItems) => {
		return List.every((item) => selectedItems.includes(item.code));
	};

	const handleExpandChange = (e, type) => {
		if (type === "location") {
			setIsLocationExpanded(!IsLocationExpanded);
		}

		if (type === "power_types") {
			setIsPowerTypesExpanded(!IsPowerTypesExpanded);
		}

		if (type === "connector_types") {
			setIsConnectorTypesExpanded(!IsConnectorTypesExpanded);
		}

		if (type === "facilities") {
			setIsFacilitiesExpanded(!IsFacilitiesExpanded);
		}

		if (type === "capabilities") {
			setIsCapabilitiesExpanded(!IsCapabilitiesExpanded);
		}

		if (type === "payment_types") {
			setIsPaymentTypesExpanded(!IsPaymentTypesExpanded);
		}

		if (type === "parking_types") {
			setIsParkingTypesExpanded(!IsParkingTypesExpanded);
		}
	};

	const toggleLocationDistance = (loc) => {
		if (loc === SelectedLocation) {
			setSelectedLocation("");
		} else {
			setSelectedLocation(loc);
		}
	};

	useEffect(() => {
		if (PowerTypes) {
			if (SelectedPowerTypes !== "ALL" && SelectedPowerTypes.length > 0) {
				const result = checkIfAllIncluded(PowerTypes, SelectedPowerTypes);
				if (result) {
					setSelectedPowerTypes("ALL");
				}
			}
		}
		if (ConnectorTypes) {
			if (SelectedConnectorTypes !== "ALL" && SelectedConnectorTypes.length > 0) {
				const result = checkIfAllIncluded(ConnectorTypes, SelectedConnectorTypes);
				if (result) {
					setSelectedConnectorTypes("ALL");
				}
			}
		}

		if (Facilities) {
			if (SelectedFacilities !== "ALL" && SelectedFacilities.length > 0) {
				const result = checkIfAllIncluded(Facilities, SelectedFacilities);
				if (result) {
					setSelectedFacilities("ALL");
				}
			}
		}

		if (Capabilities) {
			if (SelectedCapabilities !== "ALL" && SelectedCapabilities.length > 0) {
				const result = checkIfAllIncluded(Capabilities, SelectedCapabilities);
				if (result) {
					setSelectedCapabilities("ALL");
				}
			}
		}

		if (PaymentTypes) {
			if (SelectedPaymentTypes !== "ALL" && SelectedPaymentTypes.length > 0) {
				const result = checkIfAllIncluded(PaymentTypes, SelectedPaymentTypes);
				if (result) {
					setSelectedPaymentTypes("ALL");
				}
			}
		}

		if (ParkingTypes) {
			if (SelectedParkingTypes !== "ALL" && SelectedParkingTypes.length > 0) {
				const result = checkIfAllIncluded(ParkingTypes, SelectedParkingTypes);
				if (result) {
					setSelectedParkingTypes("ALL");
				}
			}
		}

		setIsPowerTypesExpanded(SelectedPowerTypes.length > 0 ? true : false);
		setIsConnectorTypesExpanded(SelectedConnectorTypes.length > 0 ? true : false);
		setIsFacilitiesExpanded(SelectedFacilities.length > 0 ? true : false);
		setIsCapabilitiesExpanded(SelectedCapabilities.length > 0 ? true : false);
		setIsPaymentTypesExpanded(SelectedPaymentTypes.length > 0 ? true : false);
		setIsParkingTypesExpanded(SelectedParkingTypes.length > 0 ? true : false);
		setIsLocationExpanded(SelectedLocation !== "" ? true : false);

		if (
			SelectedFacilities.length > 0 ||
			SelectedPowerTypes.length > 0 ||
			SelectedConnectorTypes.length > 0 ||
			SelectedCapabilities.length > 0 ||
			SelectedPaymentTypes.length > 0 ||
			SelectedParkingTypes.length > 0 ||
			SelectedLocation !== ""
		) {
			setIsSelectionEmpty(false);
		} else {
			setIsSelectionEmpty(true);
		}
	}, [
		SelectedPowerTypes,
		SelectedConnectorTypes,
		SelectedFacilities,
		SelectedCapabilities,
		SelectedPaymentTypes,
		SelectedParkingTypes,
		SelectedLocation,
	]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: {
					xs: "fixed !important",
					lg: "absolute !important",
				},
				top: {
					xs: "0",
					lg: "18.5%",
				},
				left: {
					xs: "0",
					lg: ".75%",
				},
				width: {
					xs: "100%",
					md: "50%",
					lg: "30%",
				},
				zIndex: 4,
				height: { xs: "100%", lg: "77.5%" },
				backgroundColor: "white",
				boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
			}}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "#2829a6",
					color: "white",
				}}>
				<IconButton onClick={() => props.onSetModalVisibility(false)}>
					<CloseIcon style={{ color: "white" }} />
				</IconButton>
				<Typography
					variant='subtitle2'
					sx={{ fontSize: "16px" }}>
					Filter
				</Typography>
				<IconButton sx={{ visibility: "hidden" }}>XX</IconButton>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", padding: "16px", height: "100%", overflowY: "auto" }}>
				<Typography
					variant='subtitle2'
					sx={{ alignSelf: "center", marginBottom: 1 }}>
					Basic Filters
				</Typography>
				<Accordion
					expanded={IsLocationExpanded}
					onChange={(e) => handleExpandChange(e, "location")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='power-types-content'
						id='power-types-header'>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography
								variant='subtitle2'
								sx={{ fontSize: "20px" }}>
								Location
							</Typography>
							<Typography variant='caption'>
								(Note: You can select only one option between Nearby and City/Municipality.)
							</Typography>
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ padding: "8px" }}>
							<Typography
								variant='subtitle2'
								fontSize={"16px"}>
								Nearby
							</Typography>
							<FormGroup sx={{ display: "flex", flexDirection: "row", gap: 1, p: 1 }}>
								<Chip
									sx={{
										backgroundColor: SelectedLocation === "1" && "#2829a6",
										color: SelectedLocation === "1" && "white",
									}}
									variant={SelectedLocation === "1" ? "contained" : "outlined"}
									label='< 1 km'
									onClick={() => toggleLocationDistance("1")}
								/>

								<Chip
									label='< 5 km'
									sx={{
										backgroundColor: SelectedLocation === "5" && "#2829a6",
										color: SelectedLocation === "5" && "white",
									}}
									variant={SelectedLocation === "5" ? "contained" : "outlined"}
									onClick={() => toggleLocationDistance("5")}
								/>
								<Chip
									label='< 10 km'
									sx={{
										backgroundColor: SelectedLocation === "10" && "#2829a6",
										color: SelectedLocation === "10" && "white",
									}}
									variant={SelectedLocation === "10" ? "contained" : "outlined"}
									onClick={() => toggleLocationDistance("10")}
								/>
							</FormGroup>
						</Box>
						<Box sx={{ padding: "8px" }}>
							<Typography
								variant='subtitle2'
								fontSize={"16px"}>
								Province
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
								<FormControl
									sx={{ width: "90%" }}
									size='small'>
									<InputLabel id='city-mun-label'>Select a Province</InputLabel>
									<Select
										labelId='city-mun-label'
										id='city-mun-select'
										label='Select a Province'>
										<MenuItem
											value=''
											disabled>
											<em>No Province Available</em>
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</Box>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={IsPowerTypesExpanded}
					onChange={(e) => handleExpandChange(e, "power_types")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='power-types-content'
						id='power-types-header'>
						<Typography
							variant='subtitle2'
							sx={{ fontSize: "20px" }}>
							Power Types
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={SelectedPowerTypes === "ALL" || checkIfAllIncluded(PowerTypes, SelectedPowerTypes)}
										onChange={(event) => handleFilterChange(event, "power_types", "ALL")}
									/>
								}
								label='All'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={SelectedPowerTypes.includes("AC") || SelectedPowerTypes === "ALL"}
										onChange={(event) => handleFilterChange(event, "power_types", "AC")}
									/>
								}
								label='AC'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={SelectedPowerTypes.includes("DC") || SelectedPowerTypes === "ALL"}
										onChange={(event) => handleFilterChange(event, "power_types", "DC")}
									/>
								}
								label='DC'
							/>
						</FormGroup>
					</AccordionDetails>
				</Accordion>

				<Accordion
					expanded={IsConnectorTypesExpanded}
					onChange={(e) => handleExpandChange(e, "connector_types")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='connector-types-content'
						id='connector-types-header'>
						<Typography
							variant='subtitle2'
							sx={{ fontSize: "20px" }}>
							Connector Types
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											SelectedConnectorTypes === "ALL" || checkIfAllIncluded(ConnectorTypes, SelectedConnectorTypes)
										}
										onChange={(event) => handleFilterChange(event, "connector_types", "ALL")}
									/>
								}
								label='All'
							/>
							{ConnectorTypes?.map((type) => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={SelectedConnectorTypes.includes(type.code) || SelectedConnectorTypes === "ALL"}
												onChange={(event) => handleFilterChange(event, "connector_types", type.code)}
											/>
										}
										label={type.description}
									/>
								);
							})}
						</FormGroup>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={IsFacilitiesExpanded}
					onChange={(e) => handleExpandChange(e, "facilities")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='facilities-content'
						id='facilities-header'>
						<Typography
							variant='subtitle2'
							sx={{ fontSize: "20px" }}>
							Facilities/Amenities
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup sx={{ display: "flex", flexDirection: "column", padding: "8px" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={SelectedFacilities === "ALL" || checkIfAllIncluded(Facilities, SelectedFacilities)}
										onChange={(event) => handleFilterChange(event, "facilities", "ALL")}
									/>
								}
								sx={{ mb: 3, marginLeft: "unset" }}
								label='All'
							/>
							{Facilities?.map((data, index) => {
								console.log(data);
								return (
									<>
										{index === 0 ? (
											<>
												<Typography
													variant='subtitle2'
													fontSize={"16px"}>
													Facilities
												</Typography>
												<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: "8px" }}>
													{Object.keys(data).map((facilityIndex) => {
														console.log(data[facilityIndex]);
														return (
															<FormControlLabel
																control={
																	<Checkbox
																		checked={
																			SelectedFacilities.includes(data[facilityIndex].code) ||
																			SelectedFacilities === "ALL"
																		}
																		onChange={(event) =>
																			handleFilterChange(event, "facilities", data[facilityIndex].code)
																		}
																	/>
																}
																label={data[facilityIndex].description}
															/>
														);
													})}
												</Box>
											</>
										) : (
											<>
												<Typography
													variant='subtitle2'
													fontSize={"16px"}>
													Amenities
												</Typography>
												<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: "8px" }}>
													{Object.keys(data).map((amenityIndex) => {
														return (
															<FormControlLabel
																control={
																	<Checkbox
																		checked={
																			SelectedFacilities.includes(data[amenityIndex].code) ||
																			SelectedFacilities === "ALL"
																		}
																		onChange={(event) =>
																			handleFilterChange(event, "facilities", data[amenityIndex].code)
																		}
																	/>
																}
																label={data[amenityIndex].description}
															/>
														);
													})}
												</Box>
											</>
										)}
									</>
								);
							})}
						</FormGroup>
					</AccordionDetails>
				</Accordion>

				{IsAdvanceVisible && (
					<Box sx={{ marginTop: "16px", display: "flex", flexDirection: "column" }}>
						<Typography
							variant='subtitle2'
							sx={{ alignSelf: "center", marginBottom: 1 }}>
							Advance Filters
						</Typography>
						<Accordion
							expanded={IsCapabilitiesExpanded}
							onChange={(e) => handleExpandChange(e, "capabilities")}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='capabilities-content'
								id='capabilities-header'>
								<Typography
									variant='subtitle2'
									sx={{ fontSize: "20px" }}>
									Capabilities
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													SelectedCapabilities === "ALL" || checkIfAllIncluded(Capabilities, SelectedCapabilities)
												}
												onChange={(event) => handleFilterChange(event, "capabilities", "ALL")}
											/>
										}
										label='All'
									/>
									{Capabilities?.map((capability) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={SelectedCapabilities.includes(capability.code) || SelectedCapabilities === "ALL"}
														onChange={(event) => handleFilterChange(event, "capabilities", capability.code)}
													/>
												}
												label={capability.description}
											/>
										);
									})}
								</FormGroup>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={IsPaymentTypesExpanded}
							onChange={(e) => handleExpandChange(e, "payment_types")}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='payment-types-content'
								id='payment-types-header'>
								<Typography
									variant='subtitle2'
									sx={{ fontSize: "20px" }}>
									Payment Types
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													SelectedPaymentTypes === "ALL" || checkIfAllIncluded(Capabilities, SelectedPaymentTypes)
												}
												onChange={(event) => handleFilterChange(event, "payment_types", "ALL")}
											/>
										}
										label='All'
									/>
									{PaymentTypes?.map((type) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={SelectedPaymentTypes.includes(type.code) || SelectedPaymentTypes === "ALL"}
														onChange={(event) => handleFilterChange(event, "payment_types", type.code)}
													/>
												}
												label={type.description}
											/>
										);
									})}
								</FormGroup>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={IsParkingTypesExpanded}
							onChange={(e) => handleExpandChange(e, "parking_types")}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='parking-types-content'
								id='parking-types-header'>
								<Typography
									variant='subtitle2'
									sx={{ fontSize: "20px" }}>
									Parking Types
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													SelectedParkingTypes === "ALL" || checkIfAllIncluded(ParkingTypes, SelectedParkingTypes)
												}
												onChange={(event) => handleFilterChange(event, "parking_types", "ALL")}
											/>
										}
										label='All'
									/>
									{ParkingTypes?.map((type) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={SelectedParkingTypes.includes(type.code) || SelectedParkingTypes === "ALL"}
														onChange={(event) => handleFilterChange(event, "parking_types", type.code)}
													/>
												}
												label={type.description}
											/>
										);
									})}
								</FormGroup>
							</AccordionDetails>
						</Accordion>
					</Box>
				)}
				<Button
					sx={{ display: "flex", alignSelf: "center", marginTop: "16px" }}
					onClick={() => toggleAdvance()}>
					{IsAdvanceVisible ? "Hide Advance Filters" : "	Show Advance Filters"}
				</Button>
			</Box>
			<Box sx={{ padding: "8px" }}>
				<ButtonGroup
					variant='text'
					fullWidth>
					<Button
						disabled={IsSelectionEmpty}
						onClick={toggleClearFilter}>
						Clear
					</Button>
					<Button onClick={toggleFilter}>Filter</Button>
				</ButtonGroup>
			</Box>
		</Box>
	);
};

export default FilterModal;

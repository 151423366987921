import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function Loading() {
	return (
		<div>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
				open={true}>
				<Typography sx={{ color: "white" }}>Fetching User Location...</Typography>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
}
